<template>
    <index :id="$route.params.id"/>
  </template>
  
  <script>
  import Index from './index.vue'
  export default {
    components: { Index },
    props: {
      msg: String
    },
    mounted(){
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang='scss' scoped>
  h1{
    font-size: 0.2rem;
  }
  </style>